body {
  width: 1440px;
  margin: auto;
}

.navbar {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin: 0  24px;

    .nav-branding {
      padding: 10px;
      color: #212529;
      font-family: "Inria Serif";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .nav-links {
      display: flex;
      gap: 16px;
    }
  }
}

.line {
  border-bottom: 1px solid #EDEDED;
  margin-top: 5px;
  width: 1408px;
  margin: auto;
}

.hero-content {
  display: grid;
  margin: 200px;

  h1{
    display: flex;
    margin: 0 0 32px 0;
    color: #081C15;
    text-shadow: 0px 4px 0px rgba(8, 28, 21, 0.12);
    font-family: "Frank Ruhl Libre";
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    span {
      color: #081C15;
      font-family: "Frank Ruhl Libre";
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  p {
    margin: 0 0 32px 0;
    color: #212529;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 36px */

    &.tag{
      margin-bottom: 20px;
      color: #555A60;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 28.8px */
    }
  }

  span.hero-button {
    width: fit-content;
    padding: 6px 8px;
    border-bottom: 1px dashed #053969;

    a {
      display: flex;
      gap: 8px;
    }
  }
}

.display-content-top {
  display: flex;

  div {
    flex: 1;
    height: 450px;
    margin: auto;
    border-radius: 8px;
    border: 1px solid #EDEDED;
    background: #FCF6FF;
    margin: 0 24px;

    &:first-child {
      margin-right: 40px;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.display-content-bottom {
  width: 1170px;
  margin: 160px auto 120px;

  h2 {
    color: #212529;
    text-shadow: 0px 4px 0px rgba(8, 28, 21, 0.12);
    font-family: "Frank Ruhl Libre";
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 60px auto;
  }

  .display-content-bottom-languages {
    display: flex;
    gap: 30px;

    span{
      display: flex;
      flex: 2;
      gap: 16px;
      align-items: center;
      padding: 20px;
      border-radius: 22px;
      border: 1px solid #EDEDED;
      background: #FAFAFA;

      h4, p {
        margin: 0;
      }
      h4 {
        color: #212529;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 180%; /* 36px */
      }

      p {
        color: #464646;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 25.2px */
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.footer {
  background: #111;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      text-align: center;
      color: #DBDBDB;
      text-shadow: 0px 4px 0px rgba(159, 159, 159, 0.12);
      font-family: "Frank Ruhl Libre";
      font-size: 66px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .footer-head {
      display: flex;
      padding: 9px 15.429px;
      justify-content: center;
      align-items: center;
      gap: 10.286px;
      border-radius: 25.071px;
      border: 0.643px solid #FFF;
      background: rgba(84, 84, 84, 0.04);
      color: #FFF;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 28.8px */
      margin-top: 175px;
    }

    .footer-cta {
      display: flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10.286px;
      border-radius: 50px;
      border: 1px solid #FFF;
      background: #FFF;
      color: #111;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 36px */
    }

    p {
      color: #FFF;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
    }

    .footer-social {
      display: flex;
      flex: 3;
      gap: 30px;
      margin: 132px auto 172px;
      width: 1179px;
      justify-content: space-between;

      a{
        color: #FFF;
        text-decoration: none;
        padding: 20px;
        border-bottom: 1px solid #737373;
        width: 100%;
      }

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        div {
          display: flex;
          align-items: center;
          gap: 16px;
          color: #EDEDED;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 180%; /* 36px */
        }
      }
    }
  }

  .footer-line {
    border-bottom: 1px solid #EDEDED;
    margin-top: 5px;
    width: 1408px;
    margin: auto;
  }

  .footer-bottom {
    display: flex;
    justify-content: center;

    span {
      margin: 75px 0;
      color: #EDEDED;
      font-family: "Inria Serif";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}


